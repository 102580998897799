
import { Component, Vue } from "vue-property-decorator";
import LayoutWithNavAndFooter from "@/layout/LayoutWithNavAndFooter.vue";
import Title from "@/components/Title.vue";

@Component({
  components: {
    LayoutWithNavAndFooter,
    Title
  }
})
export default class AboutUs extends Vue {
  contents = [
    {
      content: this.$t("about.1")
    },
    {
      content: this.$t("about.2")
    },
    {
      content: this.$t("about.3")
    }
  ];
  people = [
    {
      name: "Ryan",
      img: "/static/ryan.JPG",
      style: "border-radius: 45% 50% 38% 66%"
    },
    {
      name: "Andrew",
      img: "/static/andrew.JPG",
      style: "border-radius: 41% 40% 52% 61%"
    },
    {
      name: "Steven",
      img: "/static/steven.JPG",
      style: "border-radius: 30% 40% 46% 56%"
    }
  ];
}
